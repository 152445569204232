<template>
  <div>


    <div class="form">
      <el-form :inline="true" label-width="80px" :model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="form.name" clearable placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="用户名" prop="username">
              <el-input v-model="form.username" clearable placeholder="请输入用户名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="手机号" prop="phoneNo">
              <el-input v-model="form.phoneNo" clearable placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button type="primary" @click="onSubmit(form)">查询</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="状态" prop="forbidden">
              <el-select v-model="form.forbidden" clearable placeholder="请选择状态">
                <el-option label="未封禁" value="0"></el-option>
                <el-option label="封禁" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="角色" prop="role">
              <el-select v-model="form.role" clearable placeholder="请选择角色">
                <el-option v-for="item in $store.state.role"
                           :key="item.dictKey"
                           :label="item.dictValue" :value="item.dictKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="list" style="min-height: calc(100vh - 276px)">
      <div class="operation">
        <el-button @click="addClick" size="small" type="primary">添加用户</el-button>
      </div>

      <el-table
          :data="table"
          style="width: 100%">
        <el-table-column
            prop="name"
            align="center"
            label="姓名">
          <template slot-scope="scope">
            <span class="blue" @click="deta(scope.row)" >{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="username"
            align="center"
            label="用户名">
        </el-table-column>
        <el-table-column
            prop="phoneNo"
            align="center"
            label="手机号">
        </el-table-column>
        <el-table-column
            prop="roleDesc"
            align="center"
            label="角色">
        </el-table-column>
        <el-table-column
            fixed="right"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <div class="operation">
              <el-switch
                  v-model="scope.row.forbidden"
                  @change="switchChange($event,scope.row)"
                  active-text="封禁"
                  inactive-text="未封禁">
              </el-switch>
              <el-button style="margin-left: 10px;" @click="resetPassWord(scope.row)" type="text" size="small">重置密码</el-button>
              <el-button type="text" size="small" @click="del(scope.row)" >离职</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>


    <el-dialog
        :title="add_title"
        :visible.sync="add"
        width="30%"
        top="16px"
        @close="addClose"
        center>

      <div class="dia">
        <el-form label-width="70px"
                 :hide-required-asterisk="true"
                 ref="add_form"
                 :model="add_form"
                 :rules="add_rules" >
          <el-form-item label="姓名" prop="name">
            <el-input v-model="add_form.name" clearable placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="用户名" prop="username">
            <el-input v-model="add_form.username" clearable placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phoneNo">
            <el-input v-model="add_form.phoneNo" clearable placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="选择角色" prop="role">
            <el-select v-model="add_form.role" @change="addRoleChange" clearable placeholder="请选择角色">
              <el-option v-for="item in $store.state.role"
                         :key="item.dictKey"
                         :label="item.dictValue" :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择医院" prop="hospital" v-if="add_form.role == 3" >
            <el-select v-model="add_form.hospital" clearable placeholder="请选择医院">
              <el-option v-for="item in $store.state.hospital"
                         :key="item.dictKey"
                         :label="item.dictValue" :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择科室" prop="dept" v-if="add_form.role == 3" >
            <el-select v-model="add_form.dept" clearable placeholder="请选择科室">
              <el-option v-for="item in $store.state.dept"
                         :key="item.dictKey"
                         :label="item.dictValue" :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="医生职称" prop="positionalTitle" v-if="add_form.role == 3" >
            <el-input v-model="add_form.positionalTitle" clearable placeholder="请输入医生职称"></el-input>
          </el-form-item>
          <el-form-item label="擅长科目" prop="major" v-if="add_form.role == 3" >
            <el-input v-model="add_form.major" clearable placeholder="请输入擅长科目"></el-input>
          </el-form-item>
          <el-form-item label="医生头像" prop="photoPath" v-if="add_form.role == 3" >
            <el-upload
                ref="uploadThumbnail"
                :class="uploadDisabledThumbnail"
                list-type="picture-card"
                accept=".jpg,.png,.jpeg,.bmp"
                :action="action"
                :file-list="add_form.photoUrl"
                :headers="headers"
                :limit="1"
                :on-change="handleChangePicThumbnail"
                :on-success="handleAvatarSuccessThumbnail"
                :before-upload="beforeAvatarUploadThumbnail">
              <i slot="default" class="el-icon-plus"></i>
              <div class="el-upload__tip" slot="tip">只能上传jpg/jpeg/png/bmp文件</div>
              <div class="upload_image" slot="file" slot-scope="{file}">
                <!--                <img-->
                <!--                    class="el-upload-list__item-thumbnail"-->
                <!--                    :src="file.url" alt=""-->
                <!--                >-->
                <el-image
                    v-if="file.url"
                    :src="file.url"
                    :preview-src-list="[file.url]">
                </el-image>
                <el-image-viewer
                    v-if="showViewerThumbnail"
                    :on-close="closeViewer"
                    :url-list="[file.url]" />

                <span class="el-upload-list__item-actions">
<!--                <span-->
<!--                    class="el-upload-list__item-preview"-->
<!--                    @click="handlePictureCardPreview(file,showViewerThumbnail= true)"-->
<!--                >-->
<!--                  <i class="el-icon-zoom-in"></i>-->
<!--                </span>-->
                <span
                    class="el-upload-list__item-delete"
                    @click="handleRemoveThumbnail(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <div class="password" >用户初始密码为：<span>123456</span></div>
            <div class="button">
              <el-button @click="addClose">取消</el-button>
              <el-button type="primary" @click="onSubmitAdd(add_form)" >确认</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>

    </el-dialog>


  </div>
</template>

<script>
export default {
  name: "System",
  data() {
    return{
      form: {
        pageNum: 1,
        pageSize: 10,
        forbidden: null,
        name: null,
        phoneNo: null,
        role: null,
        username: null,
      },
      total: null,
      table: [],
      // 添加
      add_title: "添加用户",
      add: false,
      add_form: {
        id: null,
        name: null,
        username: null,
        phoneNo: null,
        role: null,
        dept: null,
        hospital: null,
        positionalTitle: null,
        major: null,
        photoPath: null,
        photoUrl: []
      },
      add_rules: {
        name: [
            { required: true, message: '请输入姓名', trigger: 'change' },
            { min: 2, max: 20, message: '姓名长度在 2 到 20 位', trigger: 'blur' }
        ],
        username: [
            { required: true, message: '请输入用户名', trigger: 'change' },
            { min: 2, max: 20, message: '用户名长度在 2 到 20 位', trigger: 'blur' }
        ],
        phoneNo: [
          { required: true, message: '请输入手机号', trigger: 'change' },
          { pattern: /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/, message: '手机号不正确' }
        ],
        role: [
            { required: true, message: '请选择角色', trigger: 'change' }
        ],
        dept: [
            { required: true, message: '请选择科室', trigger: 'change' }
        ],
        hospital: [
          { required: true, message: '请选择医院', trigger: 'change' }
        ],
        positionalTitle: [
          { required: true, message: '请输入医生职称', trigger: 'change' }
        ],
        major: [
          { required: true, message: '请输入擅长科目', trigger: 'change' }
        ],
        photoPath: [
          { required: true, message: '请上传医生头像', trigger: 'change' }
        ],
      },
      uploadDisabledThumbnail: null,
      showViewerThumbnail: false,
      action: process.env.VUE_APP_APIURL + "pc/admin/uploadPhoto",
      headers: {
        authorization: this.$store.state.token
      }
    }
  },
  created() {
    this.get_list(this.form);

    // console.log(this.$store.state.dept);

  },
  mounted() {

  },
  methods: {
    // 添加编辑
    onSubmitAdd(formName) {
      this.$refs["add_form"].validate((valid) => {
        if(valid) {
          // console.log(formName);
          this.$post("pc/admin",formName)
            .then(res => {
              if(res) {
                formName.id= null;
                this.addClose();
                this.get_list(this.form);
              }
            })

        }
      })
    },
    addRoleChange(e) {
      if(e != 3) {
        // this.add_form.dept= null;
        // this.add_form.hospital= null;
        // this.add_form.positionalTitle= null;
        // this.add_form.major= null;
      }
    },
    // 获取编辑用户信息
    deta(e) {
      this.$get("pc/admin/returnAdmin",e.id,true)
        .then(res => {
          if(res) {

            // console.log(res.data)

            this.add_title= "编辑用户";
            this.add= true;

            if(res.data.photoUrl) {
              let arr= [];

              this.uploadDisabledThumbnail= "disabled";
              let obj= {}
              obj["url"]= res.data.photoUrl;

              arr.push(obj)

              res.data.photoUrl= arr;

            }else {
              this.uploadDisabledThumbnail= null;
              res.data.photoUrl= [];
            }

            this.$nextTick(() => {
              this.add_form= res.data;
            })

          }
        })
    },
    // 添加
    addClick() {

      this.add_title= "添加用户";
      this.add= true;

      // console.log(this.add_form);
    },
    addClose() {


      this.$nextTick(() => {
        this.$refs["add_form"].resetFields();
        this.add_form.id= null;
        this.add_form.dept= null;
        this.add_form.hospital= null;
        this.add_form.positionalTitle= null;
        this.add_form.major= null;
        this.add_form.photoPath= null;
        this.add_form.photoUrl= [];
        this.uploadDisabledThumbnail= null;
      })


      this.add= false;
    },
    // 重置密码
    resetPassWord(e) {
      let obj= {};
      obj["id"]= e.id;
      this.$post("pc/admin/restPassword",obj)
        .then(res => {
          if(res) {
            this.$message({
              type: 'success',
              message: '密码重置成功!'
            });
          }
        })

    },
    // 离职/删除
    del(e) {

      this.$confirm('此操作将删除此用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$del("pc/admin",e.id,true)
            .then(res => {
              if(res) {
                if(res) {
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                  this.get_list(this.form);
                }
              }
            })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });

    },
    // 查询
    onSubmit(formName) {
      // console.log(formName)
      formName.pageNum= 1;
      this.get_list(formName);
    },
    switchChange(e,item) {
      let obj= {};
      obj["id"]= item.id;
      obj["forbidden"]= e;

      this.$post("pc/admin/forbidden",obj)
        .then(res => {
          if(res) {
            // console.log(res)
            this.get_list(this.form);
          }
        })

    },
    get_list(y) {
      this.$get("pc/admin",y)
        .then(res => {
          if(res) {
            // console.log(res.data.list);
            this.total= res.data.total;
            this.table= res.data.list;
          }
        })
    },
    handleCurrentChange(e) {
      this.form.pageNum= e;
      this.get_list(this.form);
    },
    handleSizeChange(e) {
      this.form.pageNum= 1;
      this.form.pageSize= e;
      this.get_list(this.form);
    },

    // 上传
    handleChangePicThumbnail(file,fileList) {
      if(fileList.length > 0) {
        this.uploadDisabledThumbnail= "disabled";
      }
    },
    handleAvatarSuccessThumbnail(res) {
      this.add_form.photoPath= res.data.photoPath;
    },
    beforeAvatarUploadThumbnail(file) {
      let extension = file.name.split(".")[1];
      let extensionList = ["JPG","jpg","jpeg","JPEG","png","PNG","bmp","BMP"];

      if (extensionList.indexOf(extension) < 0) {
        this.$message.warning("只能上传jpg/jpeg/png/bmp文件");
        return false;
      }
    },

    handlePictureCardPreview() {

    },
    closeViewer() {
      this.showViewerThumbnail= false;
    },
    handleRemoveThumbnail(file) {
      // console.log(file)
      // let Arr = this.$refs["upload"].uploadFiles
      // let index= Arr.indexOf(file)
      let num= 0;

      this.$refs["uploadThumbnail"].uploadFiles.map(item => {
        if(item.uid == file.uid) {
          this.$refs["uploadThumbnail"].uploadFiles.splice(num,1);
        }
        num ++
      })
      this.uploadDisabledThumbnail= null;

      this.add_form.photoPath= null;

    }

  }
}
</script>

<style lang="less" scoped>
.password{
  text-align: left;
  font-size: 13px;
  span{
    color: #2090ff;
  }
}
.disabled{
  /deep/ .el-upload--picture-card{
    display: none!important;
  }
}
</style>
